.youtube {
  position: absolute;
  bottom: 40%;
  left: 1%;
  z-index: 100;

  button {
    margin-top: 10px;
    margin-left: 5px;
    background: #14141d;
    color: rgb(212, 212, 212);
    border: none;
    border-radius: 5px;

    &:hover {
      color: white;
    }
  }

  .search-bar {
    background: transparent;
    opacity: 0.8;

    &:hover{
      opacity: 1;
      background-color: white;
      ::placeholder {
        color: black;
      }
    }
  }

  .player {
    display: flex;
    flex-direction: column;
  }
}
