.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  height: 15%;
  overflow: hidden;
  align-items: center;
  padding: 0 26px;
  // margin-bottom: 16px;

  .author {
    display: flex;
    align-items: center;

    span {
      color: white;
    }
  }

  .controller {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
