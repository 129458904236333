.videoIn {
  z-index: 1;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 1s;
}

.btn-primary {
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  margin-top: 32px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.description {
  color: rgb(255, 254, 254);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(50%);

  h1 {
    font-size: 17px;
  }
}
