.container {
  padding: 0;
  background-image: url('/assets/login-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  &__nav {
    max-width: 1128px;
    margin: auto;
    padding: 12px 0 16px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    flex-wrap: nowrap;

    a {
      img {
        max-width: 200px;
        max-height: 200px;
      }
      @media (max-width: 768px) {
        padding: 0 5px;
      }
    }

    .nav-menu {
      display: flex;
      flex: 1;
      margin-left: 25px;
      justify-content: flex-end;

      a {
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        text-decoration: none;
        color: white;

        span {
          font-size: 13px;
          letter-spacing: 1.4px;
          position: relative;
          padding: 0 8px;

          &:after {
            //make the hover effect on the navbar menu
            content: '';
            height: 2px;
            background: white;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -6px;
            opacity: 0;
            transform-origin: left center;
            transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
            transform: scaleX(0);
          }
        }
        &:hover {
          //make the hover effect on the navbar menu
          span:after {
            transform: scaleX(1);
            opacity: 1;
          }
        }
      }
    }
  }

  &__section {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 20px;
      color: rgb(231, 227, 227);
    }

    .form {
      margin-top: 100px;
      width: 600px;
      display: flex;
      @media (max-width: 768px) {
        margin-top: 20px;
        display: inline-block;
      }

      .icon {
        margin: 0 10px;
        display: flex;
        justify-content: center;
        background-color: #fff;
        align-items: center;
        height: 56px;
        width: 100%;
        border-radius: 28px;
        box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
          inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
        vertical-align: middle;
        z-index: 0;
        transition-duration: 167ms;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.6);
        &:hover {
          background-color: rgb(112, 111, 111);
          color: white;
        }
      }
    }
  }
}
